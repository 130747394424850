<template>
    <v-card class="primary" dark>
        <v-card-text>

            <v-card-title class="title-servicos white--text">
                Serviços Cadastrados
                <v-spacer />
            </v-card-title>

            <v-simple-table dense light>
                <thead>
                    <tr>
                        <th class="text-center">Cód. Serviço</th>
                        <th class="text-center">Serviço</th>
                        <th class="text-center text-truncate">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="dados.total > 0">
                        <tr v-for="(s, i) in servicos.lista" :key="i">
                            <td class="text-center">{{ s.idservico }}</td>
                            <td class="text-center">{{ s.nome }}</td>
                            <td class="text-center text-uppercase">{{ getStatus(s.flaginativo) }}</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td class="text-center" colspan="5">Nenhum registro encontrado.</td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>

        </v-card-text>
    </v-card>
</template>

<script>

import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
export default {
    name: "Servicos",
    mixins: [mixinFilial],
    data: () => ({
        snackbar: { status: false, timeout: 1500 },
        dialog: false,
        dialogLoading: false,
        dialogCloud: false,
        dialogEncomenda: false,
        dialogErro: {
            status: false,
            title: "",
            msg: "",
            icon: "",
            iconColor: "",
        },
        sheet: false,
        carregando: false,
        carregandoSkeleton: true,
        carregandoServicos: true,
        dados: { total: 0 },
        totais: {},
        servicos: [],
        status: {},
        statusServico:[
            { "status": "Ativo", "idstatus": 'F' },
			{ "status": "Inativo", "idstatus": 'T' },
        ],
        busca: {
            idstatus: null,
            idsituacao: null,
        },
        pgSelecionada: 0,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        listarServicos() {
            return axios
                .post(`${this.backendUrl}servicos/listar`, {

                })
                .then((res) => {
                    this.dados = res.data;
                    this.servicos = res.data;
                    this.carregandoServicos = false;
                });
        },
        getStatus(idstatus) {
            const status = this.statusServico.find(e => e.idstatus == idstatus);
            return status ? status.status : 'Desconhecido';
        },

        async init() {
            this.busca.idfilial = this.usuario.idfilial;
            const json = localStorage.getItem("filtros_contratos");
            const filtro = JSON.parse(json);
            if (filtro) {
                this.busca = filtro;
            }
            await this.listarServicos();
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
.card-servicos {
    border: 2px solid #023449;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}

.title-servicos {
    color: black;
    font-size: 18pt;
}
</style>